import React, { useState, useEffect, useRef } from 'react';
import { FormCheck, FormControl, Modal, Tab, Tabs, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import { setUser } from "../redux/userSlice";
import { useSelector } from "react-redux";



const ShelterSuppliesRegister = () => {
    const uploadFileRef = useRef()

    const { user } = useSelector(state => state.user);
    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [fetchIsLoading, setFetchIsLoading] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(null);



    const [facilityId, setFacilityId] = useState('');
    const [writerId, setWriterId] = useState('admin_006');
    const [writerContact, setWriterContact] = useState('');
    const [memo, setMemo] = useState('');

    const [toAddr, setToAddr] = useState('');
    const [fromFacilityId, setFromFacilityId] = useState('');

    const [inOutArea1, setInOutArea1] = useState(null)
    const [inOutArea2, setInOutArea2] = useState(null)
    const [inOutShelter, setInOutShelter] = useState(null)

    const [inOutAreas1, setInOutAreas1] = useState([])
    const [inOutAreas2, setInOutAreas2] = useState([])
    const [inOutShelters, setInOutShelters] = useState([])


    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);


    const [filters, setFilters] = useState({})
    const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false)


    const [itemTypes, setItemTypes] = useState([])
    const [categories1, setCategories1] = useState([])
    const [categories2, setCategories2] = useState([])
    const [categories3, setCategories3] = useState([])
    const [categories4, setCategories4] = useState([])
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)


    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setInOutAreas1)
    }, [])

    useEffect(() => {
        if (inOutAreas1.length > 0) setInOutArea1(inOutAreas1[0])
    }, [inOutAreas1])


    useEffect(() => {
        if (!inOutArea1) return
        setInOutArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1=' + inOutArea1?.value, setInOutAreas2)
    }, [inOutArea1])

    useEffect(() => {
        if (!inOutArea2) return
        setInOutShelter(null)

        ApiHelper.loadDropdown('area2Cd=' + inOutArea2?.value + '&typeCd=02', setInOutShelters, 'fac/getFACLst', 'facilityId')
    }, [inOutArea2])

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=1', setItemTypes)
    }, [])

    useEffect(() => {
        if (!filters?.type) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            return filter
        });

        setCategories2([])
        setCategories3([])
        setCategories4([])

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=2&codeLev1=' + filters?.type, setCategories1)
    }, [filters?.type])

    useEffect(() => {
        if (!filters?.cat1) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            return filter
        });

        setCategories3([])
        setCategories4([])

        var cat1 = filters?.cat1.substring(filters?.type.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=3&codeLev1=' + filters?.type + '&codeLev2=' + cat1, setCategories2)
    }, [filters?.cat1])

    useEffect(() => {
        if (!filters?.cat2) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            return filter
        });

        setCategories4([])

        var cat1 = filters?.cat1.substring(filters?.type.length)
        var cat2 = filters?.cat2.substring(filters?.cat1.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=4&codeLev1=' + filters?.type + '&codeLev2=' + cat1 + '&codeLev3=' + cat2, setCategories3)
    }, [filters?.cat2])

    useEffect(() => {
        if (!filters?.cat3) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            if (current?.cat3) filter['cat3'] = current.cat3
            return filter
        });

        var cat1 = filters?.cat1.substring(filters?.type.length)
        var cat2 = filters?.cat2.substring(filters?.cat1.length)
        var cat3 = filters?.cat3.substring(filters?.cat2.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=5&codeLev1=' + filters?.type + '&codeLev2=' + cat1 + '&codeLev3=' + cat2 + '&codeLev4=' + cat3, setCategories4)
    }, [filters?.cat3])





    const handleSubmit = (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        setIsLoadingSubmit(true);

        var formData = {
            kindCd: filters?.type,
            cat1Cd: filters?.cat1.substring(filters?.type.length),
            cat2Cd: filters?.cat2.substring(filters?.cat1.length),
            cat3Cd: filters?.cat3.substring(filters?.cat2.length),
            cat4Cd: filters?.cat4.substring(filters?.cat3.length),
            writerContact,
            facilityId,
            memo,
            writerId: userId
        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/sply/regSplyReq', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingSubmit(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoadingSubmit(false);
            });
    };

    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/auth/getUser?userId=${user.userId}`;

    useEffect(() => {
        if (!apiUrl) return;

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }
                return response.json();
            })
            .then(data => {
                const resultObject = data.resultObject;

                setUserId(resultObject.userId);
                setName(resultObject.name);
            })
            .catch(error => {
                console.error('Error occurred while fetching user details:', error);
            });
    }, [apiUrl]);

    if (fetchIsLoading) {
        return <p>Loading...</p>;
    }

    if (fetchError) {
        return <p>Error: {fetchError}</p>;
    }



    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Shelter</h3>
                <Link to={'/shelter-management'} className="sidebar-link active">Shelter management</Link>
                <Link to={'/shelter-inquiry-board'} className="sidebar-link">Shelter inquiry board</Link>
            </div>
            <div className="content-container has-sidebar has-footer">

                <form onSubmit={handleSubmit}>
                    <div className="inner-container">
                        <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                            <h3 className="page-heading">Requesting relief supplies</h3>
                            <div className="flex-fill d-flex justify-content-end align-items-center">
                                <button className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                                <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Register</button>
                            </div>
                        </div>
                        
                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="white-rounded-container mb-20px">
                            <div className="form-heading"><h5>Details</h5></div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Requested By<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                            value={writerId}
                                            placeholder="Please enter a name" disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Requested Relief Station<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-2">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Ulsan'}
                                            isSearchable={false}
                                            onChange={(e) => setToAddr(e.value)}
                                            options={inOutAreas1}
                                        />
                                    </div>
                                    <div className="dms-input-2">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Citywide'}
                                            isSearchable={false}
                                            value={inOutArea2 ?? null}
                                            onChange={(e) => setInOutArea2(e)}
                                            options={inOutAreas2}
                                        />
                                    </div>
                                    <div className="dms-input-6">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Select the shelter or warehouse'}
                                            isSearchable={false}

                                            onChange={(e) => setFacilityId(e.value)}
                                            options={inOutShelters}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Phone number</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                            value={writerContact}
                                            onChange={e => setWriterContact(e.target.value)}
                                            placeholder="Please enter a name" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Categorizing items<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.type ? itemTypes.find(value => value.value == filters?.type) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, type: e.value }))}
                                            isSearchable={false}
                                            options={itemTypes}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Item category<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.cat1 ? categories1.find(value => value.value == filters?.cat1) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, cat1: e.value }))}
                                            isSearchable={false}
                                            options={categories1}
                                        />
                                    </div>
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.cat2 ? categories2.find(value => value.value == filters?.cat2) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, cat2: e.value }))}
                                            isSearchable={false}
                                            options={categories2}
                                        />
                                    </div>
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.cat3 ? categories3.find(value => value.value == filters?.cat3) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, cat3: e.value }))}
                                            isSearchable={false}
                                            options={categories3}
                                        />
                                    </div>
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"All"}
                                            value={filters?.cat4 ? categories4.find(value => value.value == filters?.cat4) : null}
                                            onChange={(e) => setFilters(current => ({ ...current, cat4: e.value }))}
                                            isSearchable={false}
                                            options={categories4}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Number of relief supplies</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <div className="input-group">
                                            <FormControl

                                                value={writerContact}
                                                onChange={e => setWriterContact(e.target.value)}
                                                placeholder="Enter quantity" />
                                            <span className="input-group-text">EA</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label mt-1">Request details</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-12">
                                        <FormControl as="textarea" rows="8"
                                            value={memo}
                                            onChange={e => setMemo(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </form>

                <Footer />
            </div>
        </div>
    );
}

export default ShelterSuppliesRegister;