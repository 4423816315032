const ApiHelper = {
    loadDropdown: function (params, setter, url='cmn/getCmnCdLst', objectValue="fullCode", objectName="name") {
        fetch(process.env.REACT_APP_BASE_API_URL+'/'+url+'?reqPageSize=1000&reqPageNo=1&'+params)
        .then(response => {
            if (!response.ok) throw Error('Failed to load data');
            return response.json();
        })
        .then(data => {
            if (data?.resultList)
            {
                setter(data.resultList.map(value => ({value: value[objectValue], label: value[objectName]})));
            }
            else
            {
                setter([]);
            }
        })
        .catch(error => {
            setter([]);
        })
    },
}

export default ApiHelper;