import { useState } from "react";
import { Link } from "react-router-dom";

const LinksMobile = () => {
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="blank-icon" />
                    <div className="mobile-navbar-title">Links</div>
                    <div className="blank-icon" />
                </div>
            </div>
            
            <div className="content-container container flex-fill pt-3 pb-2">
                <Link className="d-block mb-2" to="/login">login</Link>
                <Link className="d-block mb-2" to="/dashboard-m">dashboard</Link>
                <Link className="d-block mb-2" to="/victim-register-m">victim-register</Link>
                <Link className="d-block mb-2" to="/edit-information-admin">edit-information-admin</Link>
                <Link className="d-block mb-2" to="/edit-information-volunteer">edit-information-volunteer</Link>
                <Link className="d-block mb-2" to="/edit-information-victim">edit-information-victim</Link>
                <Link className="d-block mb-2" to="/find-missing-children-m">find-missing-children</Link>
                <Link className="d-block mb-2" to="/find-missing-children-details-m">find-missing-children-details</Link>
                <Link className="d-block mb-2" to="/find-missing-parent-details-m">find-missing-parent-details</Link>
                <Link className="d-block mb-2" to="/register-missing-children-m">register-missing-children</Link>
                <Link className="d-block mb-2" to="/edit-missing-children-m">edit-missing-children</Link>
                <Link className="d-block mb-2" to="/edit-missing-parent-m">edit-missing-parent</Link>
                <Link className="d-block mb-2" to="/menu">menu</Link>
                <Link className="d-block mb-2" to="/qr-victim-m-1">qr-victim-m-1</Link>
                <Link className="d-block mb-2" to="/qr-victim-m-2">qr-victim-m-2</Link>
                <Link className="d-block mb-2" to="/qr-victim-m-3">qr-victim-m-3</Link>
                <Link className="d-block mb-2" to="/qr-admin-m">qr-admin</Link>
                <Link className="d-block mb-2" to="/qr-scan-m">qr-scan</Link>
                <Link className="d-block mb-2" to="/bringing-in-relief-supplies-m">bringing-in-relief-supplies</Link>
                <Link className="d-block mb-2" to="/shelter-victim-belong-m">shelter-victim-belong</Link>
                <Link className="d-block mb-2" to="/shelter-victim-notices-belong-m">shelter-victim-notices-belong</Link>
                <Link className="d-block mb-2" to="/shelter-victim-notice-details-belong-m">shelter-victim-notice-details-belong</Link>
                <Link className="d-block mb-2" to="/shelter-victim-inquiries-belong-m">shelter-victim-inquiries-belong</Link>
                <Link className="d-block mb-2" to="/shelter-victim-inquiry-details-belong-1-m">shelter-victim-inquiry-details-belong-1</Link>
                <Link className="d-block mb-2" to="/shelter-victim-inquiry-details-belong-2-m">shelter-victim-inquiry-details-belong-2</Link>
                <Link className="d-block mb-2" to="/shelter-victim-register-inquiry-belong-m">shelter-victim-register-inquiry-belong</Link>
                <Link className="d-block mb-2" to="/shelter-victim-edit-inquiry-belong-m">shelter-victim-edit-inquiry-belong</Link>
                <Link className="d-block mb-2" to="/shelter-victim-history-belong-m">shelter-victim-history-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-belong-m">shelter-admin-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-notices-belong-m">shelter-admin-notices-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-register-notice-belong-m">shelter-admin-register-notice-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-notice-details-belong-m">shelter-admin-notice-details-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-edit-notice-belong-m">shelter-admin-edit-notice-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-inquiries-belong-m">shelter-admin-inquiries-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-inquiry-details-belong-1-m">shelter-admin-inquiry-details-belong-1</Link>
                <Link className="d-block mb-2" to="/shelter-admin-inquiry-details-belong-2-m">shelter-admin-inquiry-details-belong-2</Link>
                <Link className="d-block mb-2" to="/shelter-admin-inquiry-answer-belong-m">shelter-admin-inquiry-answer-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-victims-in-shelter-belong-m">shelter-admin-victims-in-shelter-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-victims-in-shelter-details-belong-m">shelter-admin-victims-in-shelter-details-belong</Link>
                <Link className="d-block mb-2" to="/shelter-admin-relief-supplies-belong-m">shelter-admin-relief-supplies-belong</Link>
                <Link className="d-block mb-2" to="/shelter-list-no-belong-m">shelter-list-no-belong</Link>
                <Link className="d-block mb-2" to="/shelter-details-no-belong-m">shelter-details-no-belong</Link>
                <Link className="d-block mb-2" to="/shelter-notices-no-belong-m">shelter-notices-no-belong</Link>
                <Link className="d-block mb-2" to="/shelter-notice-details-no-belong-m">shelter-notice-details-no-belong</Link>
            </div>
        </div>
    );
}
 
export default LinksMobile;