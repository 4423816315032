import { useState } from "react";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { AiOutlineFileExcel } from "react-icons/ai";
import { GoPlusCircle } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";

const PreviewImageModal = ({isVisible, setIsVisible, image=null, setImage=null, fullscreen=false}) => {
    return (
        <Modal size="lg" className="modal-search-address from-bottom" fullscreen={fullscreen} show={isVisible} onHide={() => {
            if (setImage) setImage(null)
            setIsVisible(false)
        }} centered>
            <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${fullscreen ? 'pt-0' : ''} text-center`}>
                <img src={image ?? '/images/loading.svg'} className="img-fluid" />
            </Modal.Body>
        </Modal>
    );
}
 
export default PreviewImageModal;