import { useState } from "react";
import { Link } from "react-router-dom";
import { SlHome } from "react-icons/sl";
import { FiPhone } from "react-icons/fi";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";

const FindMissingChildDetails = () => {
    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Victims</h3>
                <Link to={'/victim-management'} className="sidebar-link">Victim management</Link>
                <Link to={'/find-missing-children'} className="sidebar-link active">Find missing child</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container mb-20px">
                        <p className="text-orange mb-2">[Finding lost children]</p>
                        <h3 className="page-heading mb-2">Looking for Junwon Kim.</h3>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="last-modified">Modified: 2023-07-23 10:50:00</p>
                            <div className="table-actions-container d-flex justify-content-between">
                                <button className="dms-btn dms-btn-s dms-btn-outline mr">To List</button>
                                <Link to="/edit-missing-children" className="dms-btn dms-btn-s dms-btn-outline mr">Modify</Link>
                                <button className="dms-btn dms-btn-s dms-btn-outline">Remove</button>
                            </div>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="text-center mb-20px">
                            <img src="https://i.pravatar.cc/500" className="img-fluid" />
                        </div>
                        <div className="missing-child-details">
                            <p>Name: Kim Jun-won</p>
                            <p>Age (Birthday): 1998-12-31 (7 years old)</p>
                            <p>Contact: 012-123-4123</p>
                            <p>Appearance: Height 110cm, weight 15kg<br/>- Dwarf physique<br/>- Raw hair down to waist</p>
                        </div>
                    </div>

                    <div className="page-heading-container mb-20px">
                        <h3 className="page-heading-2">Losted location</h3>
                    </div>
                    <div className="white-rounded-container mb-20px">
                        <div className="missing-child-details d-flex align-items-center">
                            <p className="text-green mb-0" style={{ lineHeight: '18px', position: 'relative', top: '-1px' }}><SlHome className="me-3" size="18px" /></p>
                            <p className="flex-fill mb-0">201-2, Dongan, some text, some text</p>
                            <button className="dms-btn dms-btn-xs dms-btn-outline">Copy</button>
                        </div>
                    </div>

                    <div className="page-heading-container mb-20px">
                        <h3 className="page-heading-2">Phone number</h3>
                    </div>
                    <div className="white-rounded-container mb-20px">
                        <div className="missing-child-details d-flex align-items-center">
                            <p className="text-green mb-0" style={{ lineHeight: '18px', position: 'relative', top: '-1px' }}><FiPhone className="me-3" size="18px" /></p>
                            <p className="flex-fill mb-0">012-4123-4123</p>
                            <button className="dms-btn dms-btn-xs dms-btn-outline">Copy</button>
                        </div>
                    </div>
                </div>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default FindMissingChildDetails;