import { useEffect, useState } from "react";

const useFetch = (url, method = 'GET') => {
    const [fetchResponse, setFetchResponse] = useState(null);
    const [fetchIsLoading, setFetchIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    
    useEffect(() => {
        if (!url) return

        setFetchIsLoading(true);
        setFetchError(null);

        fetch(url, { 
            method: method
            // headers: { 
            //     "Accept": "application/json",
            //     "Authorization": "Bearer "+localStorage.getItem("token"),
            // }
        })
        .then(response => {
            if (!response.ok) {
                // if (response.status == 401)
                // {
                //     store.dispatch(removeUser());
                //     throw Error('Unauthorized access. Please retry after login');
                // }
                // else
                    throw Error('Failed to load data');
            }

            return response.json();
        })
        .then(data => {
            setFetchResponse(data);
            setFetchIsLoading(false);
        })
        .catch(error => {
            setFetchError(error.message);
            setFetchIsLoading(false);
        })
    }, [url]);

    return {fetchResponse, fetchIsLoading, fetchError};
}
 
export default useFetch;