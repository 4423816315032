import { Modal } from "react-bootstrap";

const DeleteSelectionModal = ({isVisible, setIsVisible, handleDelete = null}) => {
    const handleYes = () => {
        setIsVisible(false)
        if (handleDelete) handleDelete()
    }

    return (
        <Modal show={isVisible} onHide={() => setIsVisible(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Remove Selected Items</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure to delete the selected items?</p>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => setIsVisible(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">Cancel</button>
                <button onClick={handleYes} className="dms-btn dms-btn-xs dms-btn-filled">Remove</button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default DeleteSelectionModal;