import { useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";

const VictimShelterBelongHistory = () => {
    const { user } = useSelector(state => state.user);
    
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false)

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="mobile-navbar-left">
                        <img src="/images/icons/back.svg" />
                    </div>
                    <div className="mobile-navbar-title">QR scan history</div>
                    <div className="blank-icon" />
                </div>
            </div>
            
            <div className="content-container flex-fill pt-0 pb-2">
                <div className="mobile-filter-box d-flex">
                    <div onClick={() => setIsVisibleFilterModal(true)} className="filter-item active">
                        <span>Year/Month/Day</span>
                        <IoChevronDown size="18px" />
                    </div>
                    <div onClick={() => setIsVisibleFilterModal(true)} className="filter-item">
                        <span>Shelter</span>
                        <IoChevronDown size="18px" />
                    </div>
                    <div onClick={() => setIsVisibleFilterModal(true)} className="filter-item">
                        <span>Scan Type</span>
                        <IoChevronDown size="18px" />
                    </div>
                </div>
                <div className="container pb-3">
                    <p className="text-gray-03 fs-xs mb-n2 mt-3">April, 2023</p>
                    <p className="text-gray-03 fs-m text-center mb-3 mt-2">No scan history</p>
                    <p className="text-gray-03 fs-xs mb-n2 mt-3">March, 2023</p>
                    <div className="mobile-card">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className={`mobile-card-title d-block text-gray-04`}>[Shelter In]</p>
                            <p className="text-gray-04 fs-xxs mb-0">2023-12-12 12:00:00</p>
                        </div>
                        <p className="mobile-card-title d-block fw-bold text-black">Test test test</p>
                        <p className="mobile-card-title d-block fw-bold text-black">Shelter A</p>
                    </div>
                    <p className="text-gray-03 fs-xs mb-n2 mt-3">February, 2023</p>
                    <div className="mobile-card">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className={`mobile-card-title d-block text-gray-04`}>[Shelter Out]</p>
                            <p className="text-gray-04 fs-xxs mb-0">2023-12-12 12:00:00</p>
                        </div>
                        <p className="mobile-card-title d-block fw-bold text-black">Test test test</p>
                        <p className="mobile-card-title d-block fw-bold text-black">Shelter A</p>
                    </div>
                    <div className="mobile-card">
                        <div className="d-flex align-items-center justify-content-between">
                            <p className={`mobile-card-title d-block text-gray-04`}>[Meal]</p>
                            <p className="text-gray-04 fs-xxs mb-0">2023-12-12 12:00:00</p>
                        </div>
                        <p className="mobile-card-title d-block fw-bold text-black">Test test test</p>
                        <p className="mobile-card-title d-block fw-bold text-black">Shelter A</p>
                    </div>
                </div>
            </div>

            <FooterMobile user={user} active="Shelter" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />

            <Modal className="modal-search-address modal-mobile-filter" fullscreen={true} show={isVisibleFilterModal} onHide={() => setIsVisibleFilterModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Search Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex pt-0">
                    <div className="content-container container flex-fill">
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">State</div>
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'All'}
                                isSearchable={false}
                                options={[
                                    { value: 'All', label: 'All' },
                                    { value: 'General', label: 'General' },
                                    { value: 'Donate', label: 'Donate' }
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Name of region/shelter</div>
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'Ulsan Metropolitan City'}
                                isSearchable={false}
                                isDisabled={true}
                                options={[
                                    { value: 'Ulsan Metropolitan City', label: 'Ulsan Metropolitan City'},
                                    { value: 'Female', label: 'Female'},
                                    { value: 'External', label: 'External'},
                                    { value: 'Facility', label: 'Facility'},
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'All Citywide'}
                                isSearchable={false}
                                options={[
                                    { value: 'All', label: 'All' },
                                    { value: 'Shelter 1', label: 'Shelter 1' },
                                    { value: 'Shelter 2', label: 'Shelter 2' },
                                    { value: 'Shelter 3', label: 'Shelter 3' },
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'All Shelter'}
                                isSearchable={false}
                                options={[
                                    { value: 'All', label: 'All' },
                                    { value: 'Shelter 1', label: 'Shelter 1' },
                                    { value: 'Shelter 2', label: 'Shelter 2' },
                                    { value: 'Shelter 3', label: 'Shelter 3' },
                                ]}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-0">
                    <div className="sticky-bottom mobile-bottom-btn-container m-0">
                        <div className="row g-0">
                            <div className="col-6">
                                <button type="button" className="dms-btn dms-btn-l dms-btn-filled-white disabled1">Initialize</button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="dms-btn dms-btn-l dms-btn-filled">Search</button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
 
export default VictimShelterBelongHistory;