import { useState } from "react";
import { FormCheck, FormControl, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import { GoArrowLeft } from "react-icons/go";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import { ImAttachment } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import PreviewImageModal from "../Components/Modals/PreviewImageModal";

const RegisterMissingChildMobile = () => {
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [isVisiblePreview, setIsVisiblePreview] = useState(false)
    const [gender, setGender] = useState('Male')

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="blank-icon" />
                    <div className="mobile-navbar-title">Register a lost child</div>
                    <div className="mobile-navbar-right">
                        <img src="/images/icons/close.svg" />
                    </div>
                </div>
            </div>
            
            <div className="content-container flex-fill pt-0 pb-2">
                <Tabs defaultActiveKey="tab1" id="tabs-example" className="underline mb-0" fill>
                    <Tab eventKey="tab1" title="Finding lost child">
                        <div className="container py-3">
                            <div className="form-heading"><h5>Lost children personal information</h5></div>

                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                <FormControl placeholder="Please enter a name" />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Gender</div>
                                <div className="mobile-inline-checkbox-button">
                                    <div className={gender == 'Male' ? 'checked' : ''} onClick={() => setGender('Male')}>Male</div>
                                    <div className={gender == 'Female' ? 'checked' : ''} onClick={() => setGender('Female')}>Female</div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Birthday</div>
                                <FormControl placeholder="Enter only number (YYYYMMDD)" />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Address<span className="label-ast">*</span></div>
                                <div className="d-flex">
                                    <div className="flex-fill me-2">
                                        <FormControl placeholder="Please enter value" disabled />
                                    </div>
                                    <button type="button" onClick={() => setIsVisibleAddress(true)}  className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find</button>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label"></div>
                                <FormControl placeholder="Please enter value" disabled />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label"></div>
                                <FormControl placeholder="Please enter value" />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label mt-1">Description</div>
                                <FormControl as="textarea" rows="8" placeholder="Please enter a description" />
                            </div>

                            <div className="mobile-section-separator"/>

                            <div className="form-heading"><h5>Parents information</h5></div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                <FormControl placeholder="Please enter a name" />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Contact<span className="label-ast">*</span></div>
                                <FormControl placeholder="Please enter value" />
                            </div>
                            
                            <div className="mobile-section-separator"/>
                            
                            <div className="form-heading"><h5>Thumbnail</h5></div>

                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100"><GoPlusCircle size="22px" />Find File</button>
                            <div className="input-helper">Upload up to 1 images.<br/>Uploadable extensions: JPG, JPEG, PNG</div>

                            <div className="mobile-card mt-2">
                                <div className="missing-child-details d-flex align-items-center">
                                    <p className="text-green mb-0" style={{ lineHeight: '18px', position: 'relative', top: '-1px' }}><ImAttachment className="me-2" size="18px" /></p>
                                    <p className="flex-fill mb-0">012371923712.jpg</p>
                                    <button type="button" onClick={() => setIsVisiblePreview(true)} className="dms-btn dms-btn-xs dms-btn-outline me-2">Preview</button>
                                    <IoClose size="18px" />
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="tab2" title="Finding parents">
                        <div className="container py-3">
                            <div className="form-heading"><h5>Lost Child's Personal information</h5></div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                <FormControl placeholder="Please enter a name" />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Gender</div>
                                <div className="mobile-inline-checkbox-button">
                                    <div className={gender == 'Male' ? 'checked' : ''} onClick={() => setGender('Male')}>Male</div>
                                    <div className={gender == 'Female' ? 'checked' : ''} onClick={() => setGender('Female')}>Female</div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Birthday</div>
                                <FormControl placeholder="Enter only number (YYYYMMDD)" />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label mt-1">Description</div>
                                <FormControl as="textarea" rows="8" placeholder="Please enter a description" />
                            </div>
                            
                            <div className="mobile-section-separator"/>
                            
                            <div className="form-heading"><h5>Temporary Guardian information</h5></div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                <FormControl placeholder="Please enter a name" />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">Contact<span className="label-ast">*</span></div>
                                <FormControl placeholder="Please enter value" />
                            </div>
                            <div className="form-group mb-2">
                                <div className="dms-form-label align-self-center">Protected Location</div>
                                <div className="d-flex">
                                    <div className="flex-fill me-2">
                                        <FormControl placeholder="Please enter value" disabled />
                                    </div>
                                    <button type="button" onClick={() => setIsVisibleAddress(true)}  className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find</button>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label"></div>
                                <FormControl placeholder="Please enter value" disabled />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label"></div>
                                <FormControl placeholder="Please enter value" />
                            </div>
                            
                            <div className="mobile-section-separator"/>
                            
                            <div className="form-heading"><h5>Thumbnail</h5></div>

                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100"><GoPlusCircle size="22px" />Find File</button>
                            <div className="input-helper">Upload up to 1 images.<br/>Uploadable extensions: JPG, JPEG, PNG</div>

                            <div className="mobile-card mt-2">
                                <div className="missing-child-details d-flex align-items-center">
                                    <p className="text-green mb-0" style={{ lineHeight: '18px', position: 'relative', top: '-1px' }}><ImAttachment className="me-2" size="18px" /></p>
                                    <p className="flex-fill mb-0">012371923712.jpg</p>
                                    <button type="button" onClick={() => setIsVisiblePreview(true)} className="dms-btn dms-btn-xs dms-btn-outline me-2">Preview</button>
                                    <IoClose size="18px" />
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            
            <SearchAddressModal isVisible={isVisibleAddress} setIsVisible={setIsVisibleAddress} fullscreen={true} />
            <PreviewImageModal isVisible={isVisiblePreview} setIsVisible={setIsVisiblePreview} fullscreen={true} />
        </div>
    );
}
 
export default RegisterMissingChildMobile;