import { useState } from "react";
import { FormCheck, FormControl, FormSelect, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import { GoPlusCircle } from "react-icons/go";
import FooterMobile from "../Components/FooterMobile";
import { IoChevronDown } from "react-icons/io5";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";

const FindMissingChildMobile = () => {
    const { user } = useSelector(state => state.user);
    
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [selectedItems, setSelectedItems] = useState([])
    const [isVisibleFilterChildModal, setIsVisibleFilterChildModal] = useState(false)
    const [isVisibleFilterParentModal, setIsVisibleFilterParentModal] = useState(false)

    const handleCheckItem = (id) => {
        setSelectedItems(current => {
            if (current.includes(id))
            {
                return current.filter(value => value != id)
            }
            else
            {
                return [...current, id]
            }
        })
    }

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="blank-icon" />
                    <div className="mobile-navbar-title">Find a missing child/parent</div>
                    <div className="mobile-navbar-right">
                        <img src="/images/icons/plus.svg" />
                    </div>
                </div>
            </div>
            
            <div className="content-container flex-fill pt-0 pb-2">
                <Tabs defaultActiveKey="tab1" id="tabs-example" className="underline mb-0" fill>
                    <Tab eventKey="tab1" title="Find missing child">
                        <div className="mobile-filter-box d-flex">
                            <div onClick={() => setIsVisibleFilterChildModal(true)} className="filter-item">
                                <span>Target location</span>
                                <IoChevronDown size="18px" />
                            </div>
                            <div onClick={() => setIsVisibleFilterChildModal(true)} className="filter-item">
                                <span>State/Children name</span>
                                <IoChevronDown size="18px" />
                            </div>
                        </div>
                        <div className="container py-3">
                            <button type="button" className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 align-self-center"><GoPlusCircle size="22px" />Register</button>
                            
                            {items.map(value => (
                                <div key={value.id} className="mobile-card">
                                    <div className="d-flex align-items-center mb-2">
                                        <div>
                                            <p className={`mobile-card-title ${value.id%2 == 0 ? 'text-orange' : 'text-gray-04'}`}>[{value.id%2 == 0 ? 'Finding' : 'Found'} Lost Children]</p>&nbsp;
                                            <p className="mobile-card-title text-black">Test name</p>
                                        </div>
                                        <Link to={"/find-missing-children-details-m"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Details</Link>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <p className="mobile-card-text">1993-12-02</p>
                                            <p className="mobile-card-text">Test Test Test Test</p>
                                            <p className="mobile-card-text">012-41234-123123</p>
                                        </div>
                                        <div className="ms-auto">
                                            <img src="https://i.pravatar.cc/100" className="img-fluid mobile-card-thumb" />
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <button type="button" className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 mt-3 align-self-center">View more (1/166)</button>
                        </div>
                    </Tab>
                    <Tab eventKey="tab2" title="Find missing parents">
                        <div className="mobile-filter-box d-flex">
                            <div onClick={() => setIsVisibleFilterParentModal(true)} className="filter-item">
                                <span>Target location</span>
                                <IoChevronDown size="18px" />
                            </div>
                            <div onClick={() => setIsVisibleFilterParentModal(true)} className="filter-item">
                                <span>State/Children name</span>
                                <IoChevronDown size="18px" />
                            </div>
                        </div>
                        <div className="container py-3">
                            <button type="button" className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 align-self-center"><GoPlusCircle size="22px" />Register</button>
                            
                            {items.map(value => (
                                <div key={value.id} className="mobile-card">
                                    <div className="d-flex align-items-center mb-2">
                                        <div>
                                            <p className={`mobile-card-title ${value.id%2 == 0 ? 'text-green' : 'text-gray-04'}`}>[{value.id%2 == 0 ? 'Finding' : 'Found'} Parents]</p>&nbsp;
                                            <p className="mobile-card-title text-black">Test name</p>
                                        </div>
                                        <Link to={"/find-missing-parent-details-m"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">Details</Link>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <p className="mobile-card-text">1993-12-02</p>
                                            <p className="mobile-card-text">Test Test Test Test</p>
                                            <p className="mobile-card-text">012-41234-123123</p>
                                        </div>
                                        <div className="ms-auto">
                                            <img src="https://i.pravatar.cc/100" className="img-fluid mobile-card-thumb" />
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <button type="button" className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 mt-3 align-self-center">View more (1/166)</button>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            
            <FooterMobile user={user} isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />

            <Modal className="modal-search-address modal-mobile-filter" fullscreen={true} show={isVisibleFilterChildModal} onHide={() => setIsVisibleFilterChildModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Search Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex pt-0">
                    <div className="content-container container flex-fill">
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Target Location</div>
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'Ulsan Metropolitan City'}
                                isSearchable={false}
                                isDisabled={true}
                                options={[
                                    { value: 'Ulsan Metropolitan City', label: 'Ulsan Metropolitan City'},
                                    { value: 'Female', label: 'Female'},
                                    { value: 'External', label: 'External'},
                                    { value: 'Facility', label: 'Facility'},
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'All Citywide'}
                                isSearchable={false}
                                options={[
                                    { value: 'All', label: 'All' },
                                    { value: 'Shelter 1', label: 'Shelter 1' },
                                    { value: 'Shelter 2', label: 'Shelter 2' },
                                    { value: 'Shelter 3', label: 'Shelter 3' },
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">State</div>
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'All'}
                                isSearchable={false}
                                options={[
                                    { value: 'Ulsan Metropolitan City', label: 'Ulsan Metropolitan City'},
                                    { value: 'Female', label: 'Female'},
                                    { value: 'External', label: 'External'},
                                    { value: 'Facility', label: 'Facility'},
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Name</div>
                            <FormControl placeholder="Please enter value" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-0">
                    <div className="sticky-bottom mobile-bottom-btn-container m-0">
                        <div className="row g-0">
                            <div className="col-6">
                                <button type="button" className="dms-btn dms-btn-l dms-btn-filled-white disabled1">Initialize</button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="dms-btn dms-btn-l dms-btn-filled">Search</button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal className="modal-search-address modal-mobile-filter" fullscreen={true} show={isVisibleFilterParentModal} onHide={() => setIsVisibleFilterParentModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Search Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex pt-0">
                    <div className="content-container container flex-fill">
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Target Location</div>
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'Ulsan Metropolitan City'}
                                isSearchable={false}
                                isDisabled={true}
                                options={[
                                    { value: 'Ulsan Metropolitan City', label: 'Ulsan Metropolitan City'},
                                    { value: 'Female', label: 'Female'},
                                    { value: 'External', label: 'External'},
                                    { value: 'Facility', label: 'Facility'},
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'All Citywide'}
                                isSearchable={false}
                                options={[
                                    { value: 'All', label: 'All' },
                                    { value: 'Shelter 1', label: 'Shelter 1' },
                                    { value: 'Shelter 2', label: 'Shelter 2' },
                                    { value: 'Shelter 3', label: 'Shelter 3' },
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">State</div>
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                placeholder={'All'}
                                isSearchable={false}
                                options={[
                                    { value: 'Ulsan Metropolitan City', label: 'Ulsan Metropolitan City'},
                                    { value: 'Female', label: 'Female'},
                                    { value: 'External', label: 'External'},
                                    { value: 'Facility', label: 'Facility'},
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">Name</div>
                            <FormControl placeholder="Please enter value" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-0">
                    <div className="sticky-bottom mobile-bottom-btn-container m-0">
                        <div className="row g-0">
                            <div className="col-6">
                                <button type="button" className="dms-btn dms-btn-l dms-btn-filled-white disabled1">Initialize</button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="dms-btn dms-btn-l dms-btn-filled">Search</button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
 
export default FindMissingChildMobile;