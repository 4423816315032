import { useState } from "react";
import { FormCheck, FormControl, FormSelect, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";

const FindMissingChild = () => {
    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [selectedItems, setSelectedItems] = useState([])

    const handleCheckItem = (id) => {
        setSelectedItems(current => {
            if (current.includes(id))
            {
                return current.filter(value => value != id)
            }
            else
            {
                return [...current, id]
            }
        })
    }
    
    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Victims</h3>
                <Link to={'/victim-management'} className="sidebar-link">Victim management</Link>
                <Link to={'/find-missing-children'} className="sidebar-link active">Find missing child</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Find missing child</h3>
                        <Link to="/register-missing-children" className="dms-btn dms-btn-s dms-btn-outline">Registration</Link>
                    </div>

                    <Tabs defaultActiveKey="tab1" id="tabs-example" fill>
                        <Tab eventKey="tab1" title="Find missing child">
                            <div className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Select a region/shelter</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    placeholder={'Ulsen City'}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    options={[
                                                        { value: 'Ulsen City', label: 'Ulsen City' },
                                                        { value: 'Test', label: 'Test' },
                                                        { value: 'Test Test', label: 'Test Test' }
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    placeholder={'Entire City'}
                                                    isSearchable={false}
                                                    options={[
                                                        { value: 'Entire city', label: 'Entire city' },
                                                        { value: 'Area 1', label: 'Area 1' },
                                                        { value: 'Area 2', label: 'Area 2' },
                                                        { value: 'Area 3', label: 'Area 3' },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Name</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-3">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    placeholder={'Finding'}
                                                    isSearchable={false}
                                                    options={[
                                                        { value: 'Finding', label: 'Finding' },
                                                        { value: 'Shelter 1', label: 'Shelter 1' },
                                                        { value: 'Shelter 2', label: 'Shelter 2' },
                                                        { value: 'Shelter 3', label: 'Shelter 3' },
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-lg-5">
                                                <FormControl placeholder="Please enter a name" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                    <button className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                </div>
                            </div>
                            <div className="table-actions-container mb-20px">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="search-result">Search Result <span>99</span> Items</p>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{label: 'View By 10'}}
                                            isSearchable={false}
                                            options={[
                                                { value: 'View By 10', label: 'View By 10' },
                                                { value: 'View By 20', label: 'View By 20' },
                                                { value: 'View By 30', label: 'View By 30' },
                                            ]}
                                        />
                                        <button className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                                    </div>
                                </div>
                            </div>
                            <table className="table dms-table-seperate">
                                <thead>
                                    <tr>
                                        <th>Children Name</th>
                                        <th>Gender</th>
                                        <th>Birthday</th>
                                        <th>Lost Location</th>
                                        <th>Register time</th>
                                        <th>Status</th>
                                        <th>View Detail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(value => (
                                        <tr key={value.id}>
                                            <td>Test Test</td>
                                            <td>Male</td>
                                            <td>1993-12-02</td>
                                            <td>Test Test Test Test</td>
                                            <td>2023-07-23 11:21:23</td>
                                            <td className={value.id%2 == 0 ? '' : 'text-green'}>{value.id%2 == 0 ? 'Found' : 'Finding'}</td>
                                            <td style={{ width: "120px" }}><Link to={"/find-missing-children-details"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <ReactPaginate
                                //onPageChange={handlePageClick}
                                forcePage={0}
                                pageCount={5}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                previousLabel={<LuChevronLeft size="20px" />}
                                nextLabel={<LuChevronRight size="20px" />}
                                breakLabel="..."
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item"
                                previousClassName="page-item page-previous"
                                nextClassName="page-item page-next"
                                breakClassName="page-item"
                                pageLinkClassName="page-link"
                                activeClassName="active"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                breakLinkClassName="page-link"
                            />
                        </Tab>
                        <Tab eventKey="tab2" title="Find missing parents">
                            <div className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Select a region/shelter</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    placeholder={'Ulsen City'}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    options={[
                                                        { value: 'Ulsen City', label: 'Ulsen City' },
                                                        { value: 'Test', label: 'Test' },
                                                        { value: 'Test Test', label: 'Test Test' }
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    placeholder={'Entire City'}
                                                    isSearchable={false}
                                                    options={[
                                                        { value: 'Entire city', label: 'Entire city' },
                                                        { value: 'Area 1', label: 'Area 1' },
                                                        { value: 'Area 2', label: 'Area 2' },
                                                        { value: 'Area 3', label: 'Area 3' },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">Name</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-3">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    placeholder={'Finding'}
                                                    isSearchable={false}
                                                    options={[
                                                        { value: 'Finding', label: 'Finding' },
                                                        { value: 'Shelter 1', label: 'Shelter 1' },
                                                        { value: 'Shelter 2', label: 'Shelter 2' },
                                                        { value: 'Shelter 3', label: 'Shelter 3' },
                                                    ]}
                                                />
                                            </div>
                                            <div className="col-lg-5">
                                                <FormControl placeholder="Please enter a name" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                                    <button className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                                </div>
                            </div>
                            <div className="table-actions-container mb-20px">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="search-result">Search Result <span>99</span> Items</p>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{label: 'View By 10'}}
                                            isSearchable={false}
                                            options={[
                                                { value: 'View By 10', label: 'View By 10' },
                                                { value: 'View By 20', label: 'View By 20' },
                                                { value: 'View By 30', label: 'View By 30' },
                                            ]}
                                        />
                                        <button className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                                    </div>
                                </div>
                            </div>
                            <table className="table dms-table-seperate">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Birthday</th>
                                        <th>Lost Location</th>
                                        <th>Register time</th>
                                        <th>Status</th>                                        
                                        <th>View Detail</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(value => (
                                        <tr key={value.id}>
                                            <td>Test Test</td>
                                            <td>Male</td>
                                            <td>1993-12-02</td>
                                            <td>Test Test Test Test</td>
                                            <td>2023-07-23 11:21:23</td>
                                            <td className={value.id%2 == 0 ? '' : 'text-green'}>{value.id%2 == 0 ? 'Found' : 'Finding'}</td>
                                            <td style={{ width: "120px" }}><Link to={"/find-missing-parent-details"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <ReactPaginate
                                //onPageChange={handlePageClick}
                                forcePage={0}
                                pageCount={5}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                previousLabel={<LuChevronLeft size="20px" />}
                                nextLabel={<LuChevronRight size="20px" />}
                                breakLabel="..."
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item"
                                previousClassName="page-item page-previous"
                                nextClassName="page-item page-next"
                                breakClassName="page-item"
                                pageLinkClassName="page-link"
                                activeClassName="active"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                breakLinkClassName="page-link"
                            />
                        </Tab>
                    </Tabs>
                </div>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default FindMissingChild;