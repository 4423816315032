import { useState } from "react";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { AiOutlineFileExcel } from "react-icons/ai";
import Footer from "../Components/Footer";

const ShelterSuppliesDetailsModal = ({isVisible, setIsVisible}) => {
    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    
    return (
        <Modal size="lg" show={isVisible} onHide={() => setIsVisible(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>View shelter details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table dms-table-seperate">
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Sent from</th>
                            <th>From address</th>
                            <th>Sent to</th>
                            <th>To address</th>
                            <th>Status</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(value => (
                            <tr key={value.id}>
                                <td>2023-10-12 12:22:12</td>
                                <td>Test Test Test</td>
                                <td>Ulsan</td>
                                <td>South District</td>
                                <td>LF Relief Center</td>
                                <td className={value.id%2 == 0 ? 'text-orange' : 'text-green'}>{value.id%2 == 0 ? 'Storing' : 'Unstoring'}</td>
                                <td className={`text-end ${value.id%2 == 0 ? 'text-orange' : 'text-green'}`}>{value.id%2 == 0 ? '+99,999,999' : '-99,999,999'}
                                {/* <br /><small className="text-gray-03">99,999,999</small> */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    );
}
 
export default ShelterSuppliesDetailsModal;