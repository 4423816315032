import React, { useState, useEffect } from "react";
import KakaoMap from "./KakaoMap";

const Dashboard = () => {
    return (
        <div className="p-0">
            <KakaoMap />
        </div>
    );
}

export default Dashboard;