import { useState } from "react";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { AiOutlineFileExcel } from "react-icons/ai";
import { GoPlusCircle } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";

const PreviewQrMobile = ({data=null, isVisible, setIsVisible}) => {
    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}])
    const [value, setValue] = useState('')
    
    return (
        <Modal className="modal-qr-preview" fullscreen={true} show={isVisible} onHide={() => setIsVisible(false)} centered>
            <Modal.Body className="text-center py-4 d-flex flex-column justify-content-between">
                <img src="/images/logo.png" className="img-fluid w-75 mx-auto"/>
                <div>
                    <img src={data?.image ?? '/images/loading.svg'} className="img-fluid w-75" />
                    <p className="mt-3 fs-s">{data?.name}</p>
                </div>
                <div onClick={() => setIsVisible(false)} className="bg-white rounded-circle d-flex align-items-center justify-content-center my-1 mx-auto" style={{width: '50px', height: '50px', boxShadow: '0 0.15rem .7rem rgba(0, 0, 0, 0.15)'}}>
                    <IoCloseOutline size="30px" />
                </div>
            </Modal.Body>
        </Modal>
    );
}
 
export default PreviewQrMobile;