import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {
            typeCd: null,
            type: null,
            token: null,
            facilityId: null,
            userId: null,
            userName: null,
            resultObject: null,
        }
    }, 
    reducers: {
        setUser: (state, action)=> {
            localStorage.setItem("token", action.payload.token)
            state.user = action.payload
        },
        removeUser: (state, action)=> {
            localStorage.removeItem("token");
            state.user = {
                typeCd: null,
                type: null,
                token: null,
                facilityId: null,
                userId: null,
                userName: null,
                resultObject: null,
            }
        },
        updateUser: (state, action)=> {
            state.user[action.payload.key] = action.payload.value
        },
    }
})

export const { setUser, removeUser, updateUser } = userSlice.actions;
export default userSlice.reducer;