import { Modal } from "react-bootstrap";

const DeleteModalMobile = ({isVisible, setIsVisible, handleDelete = null}) => {
    const handleYes = () => {
        setIsVisible(false)
        if (handleDelete) handleDelete()
    }

    return (
        <Modal className="mobile-modal-2" show={isVisible} onHide={() => setIsVisible(false)} centered>
            <Modal.Body>
                <p>Are you sure you want to delete it?</p>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => setIsVisible(false)} className="dms-btn dms-btn-s">No</button>
                <button onClick={handleYes} className="dms-btn dms-btn-s">Yes</button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default DeleteModalMobile;