import { useState } from "react";
import { FormCheck, FormControl, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";

const RegisterMissingChild = () => {
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Victims</h3>
                <Link to={'/victim-management'} className="sidebar-link">Victim management</Link>
                <Link to={'/find-missing-children'} className="sidebar-link active">Find missing child</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Register a lost child</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <button className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Register</button>
                        </div>
                    </div>

                    <Tabs defaultActiveKey="tab1" id="tabs-example" fill>
                        <Tab eventKey="tab1" title="Find missing child">
                        <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>Lost Child's Personal information</h5></div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl placeholder="Please enter a name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Gender<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-3">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                placeholder={'Male'}
                                                isSearchable={false}
                                                options={[
                                                    { value: 'Male', label: 'Male'},
                                                    { value: 'Female', label: 'Female'},
                                                    { value: 'External', label: 'External'},
                                                    { value: 'Facility', label: 'Facility'},
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Birthday</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container mb-2">
                                    <div className="dms-form-label align-self-center">Losted Location</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl placeholder="Please enter value" disabled />
                                        </div>
                                        <div className="dms-input-4">
                                            <button className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container mb-2">
                                    <div className="dms-form-label"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl placeholder="Please enter value" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl placeholder="Please enter value" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label mt-1">Description</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl as="textarea" rows="8" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>Parents information</h5></div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl placeholder="Please enter a name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Contact<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl placeholder="Please enter value" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>Thumbnail</h5></div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label mt-2">Upload a photo</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl placeholder="Please enter a name" />
                                            <div className="input-helper">Upload up to 1 images. Uploadable extensions: JPG, JPEG, PNG</div>
                                        </div>
                                        <div className="dms-input-4">
                                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active"><GoPlusCircle size="22px" />Find File</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="tab2" title="Find missing parents">
                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>Lost Child's Personal information</h5></div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl placeholder="Please enter a name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Gender<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-3">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                placeholder={'Male'}
                                                isSearchable={false}
                                                options={[
                                                    { value: 'Male', label: 'Male'},
                                                    { value: 'Female', label: 'Female'},
                                                    { value: 'External', label: 'External'},
                                                    { value: 'Facility', label: 'Facility'},
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Birthday</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label mt-1">Description</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl as="textarea" rows="8" />  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>Temporary Guardian information</h5></div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Name<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl placeholder="Please enter a name" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">Contact<span className="label-ast">*</span></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl placeholder="Please enter value" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container mb-2">
                                    <div className="dms-form-label align-self-center">Protected Location</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl placeholder="Please enter value" disabled />
                                        </div>
                                        <div className="dms-input-4">
                                            <button className="dms-btn dms-btn-s dms-btn-outline align-self-center">Find address</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container mb-2">
                                    <div className="dms-form-label"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl placeholder="Please enter value" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label"></div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl placeholder="Please enter value" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>Thumbnail</h5></div>
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label mt-2">Upload a photo</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-8">
                                            <FormControl placeholder="Please enter a name" />
                                            <div className="input-helper">Upload up to 1 images. Uploadable extensions: JPG, JPEG, PNG</div>
                                        </div>
                                        <div className="dms-input-4">
                                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active"><GoPlusCircle size="22px" />Find File</button>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </Tab>
                    </Tabs>
                </div>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default RegisterMissingChild;