import { useState } from "react";
import { Link } from "react-router-dom";
import { SlHome } from "react-icons/sl";
import { FiPhone } from "react-icons/fi";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import DeleteModalMobile from "../Components/Modals/DeleteModalMobile";

const FindMissingParentDetailsMobile = () => {
    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])    
    const [isVisibleDelete, setIsVisibleDelete] = useState(false)

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="mobile-navbar-left">
                        <img src="/images/icons/back.svg" />
                    </div>
                    <div className="mobile-navbar-title">Find a missing child/parent</div>
                    <div className="mobile-navbar-right">
                        <img src="/images/icons/home.svg" />
                    </div>
                </div>
            </div>
            
            <div className="content-container container flex-fill pb-4">
                <div className="page-heading-container mb-20px mt-0">
                    <p className="text-orange mb-2">[Finding lost children]</p>
                    <h3 className="page-heading mb-2">Looking parents of Junwon Kim.</h3>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="last-modified">2023-07-23</p>
                        <div className="table-actions-container d-flex justify-content-between">
                            <Link to="/edit-missing-parent" className="dms-btn dms-btn-s dms-btn-outline mr">Modify</Link>
                            <button type="button" onClick={() => setIsVisibleDelete(true)} className="dms-btn dms-btn-s dms-btn-outline">Remove</button>
                        </div>
                    </div>
                    <ReactSelect
                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                        classNamePrefix="select"
                        className="dropdown-sm mt-2"
                        value={{label: 'Finding'}}
                        isSearchable={false}
                        options={[
                            { value: 'Finding', label: 'Finding' },
                            { value: 'Found', label: 'Found' },
                        ]}
                    />
                </div>

                <div className="mobile-card mb-12px">
                    <div className="text-center mb-20px">
                        <img src="https://i.pravatar.cc/500" className="img-fluid" />
                    </div>
                    <div className="missing-child-details">
                        <p>Name: Kim Jun-won</p>
                        <p>Age (Birthday): 1998-12-31 (7 years old)</p>
                        <p>Contact: 012-123-4123</p>
                        <p>Appearance: Height 110cm, weight 15kg<br/>- Dwarf physique<br/>- Raw hair down to waist</p>
                    </div>
                </div>

                <div className="page-heading-container">
                    <h3 className="page-heading-2">Contact</h3>
                </div>
                <div className="mobile-card mb-20px">
                    <div className="missing-child-details d-flex align-items-center">
                        <p className="text-green mb-0" style={{ lineHeight: '18px', position: 'relative', top: '-1px' }}><SlHome className="me-3" size="18px" /></p>
                        <p className="flex-fill mb-0">201-2, Dongan, some text, some text</p>
                        <button className="dms-btn dms-btn-xs dms-btn-outline">Copy</button>
                    </div>
                </div>

                <div className="page-heading-container">
                    <h3 className="page-heading-2">Phone number</h3>
                </div>
                <div className="mobile-card">
                    <div className="missing-child-details d-flex align-items-center">
                        <p className="text-green mb-0" style={{ lineHeight: '18px', position: 'relative', top: '-1px' }}><FiPhone className="me-3" size="18px" /></p>
                        <p className="flex-fill mb-0">012-4123-4123</p>
                        <button className="dms-btn dms-btn-xs dms-btn-outline">Copy</button>
                    </div>
                </div>
            </div>

            <div className="container content-container pt-0">
                <button type="button" className="dms-btn dms-btn-to-list-mobile dms-btn-outline w-100 px-0 align-self-center">To List</button>
            </div>

            <DeleteModalMobile isVisible={isVisibleDelete} setIsVisible={setIsVisibleDelete} />
        </div>
    );
}
 
export default FindMissingParentDetailsMobile;